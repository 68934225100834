<template>
  <div class="warp adv-video-box">
    <Swiper ref="swiper" :options="advList.length > 1 ? swiperOption : swiperOption2" v-if="advList.length > 0">
      <SwiperSlide class="swiperSlide" v-for="(item, index) in advList" :key="index">
        <img :src="place" class="advimg" @click="goAdv(item)" :data-item="JSON.stringify(item)" :key="index" />
      </SwiperSlide>
      <div class="swiper-pagination" slot="pagination" v-if="advList.length != 1 && hasPagination"></div>
    </Swiper>
  </div>
</template>
<script>
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { imgDecyptApi } from '@/api/app';
import { mapGetters } from 'vuex';
import { jumpAdv } from '@/utils/getConfig';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    advList: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    hasPagination: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  computed: {
    ...mapGetters({
      imgApi: 'imgApi',
    }),
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  // activated() {
  // this.swiper.autoplay.stop();
  // setTimeout(() => {
  //   this.swiper.slideTo(this.swiper.activeIndex + 1,0)
  //   this.swiper.autoplay.start()
  // }, 1000);
  // },
  data() {
    return {
      place: require('@/assets/png/plac_h.png'),
      // 轮播图配置
      swiperOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass: 'my-bullet-active',
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        on: {
          click: function (e) {
            let item = JSON.parse(e.target.dataset.item);
            // console.log(item)
            // console.log(e.target.dataset.item)
            jumpAdv(item);
          },
        },
      },
      swiperOption2: {
        loop: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass: 'my-bullet-active',
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
      },
    };
  },
  mounted() {
    let imgApi = this.imgApi;
    if (imgApi.slice(-1) != '/') {
      imgApi = imgApi + '/';
    }
    let dom = document.querySelectorAll('.adv-video-box .swiperSlide');
    // console.log(dom[0].getAttribute("data-swiper-slide-index"))
    let domlen = dom.length;
    for (let i = 0; i < domlen; i++) {
      let imgDom = dom[i].querySelector('img');
      imgDecyptApi(
        imgApi + (this.advList.length > 1 ? this.advList[dom[i].getAttribute('data-swiper-slide-index')].cover : this.advList[0].cover),
      ).then((url) => {
        if (imgDom) {
          imgDom.src = url;
        }
      });
    }
  },
  methods: {
    /**
     * * 跳转广告
     *  * @param {*} item 广告信息
     */
    goAdv(item) {
      // console.log(item)
      jumpAdv(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.advimg {
  width: 100%;
  height: 100%;
  text-align: center;
  background: #242424;
}

.warp {
  /deep/ .swiper-pagination {
    bottom: 6px;
    left: auto;
    width: 100%;
    right: 20px;
    text-align: right;
    margin-right: 20px;
    z-index: 999;
  }

  /deep/ .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 2px 4px;
    background: linear-gradient($pumpkinOrange, $brownishOrange);
    opacity: 0.5;
  }

  /deep/ .my-bullet-active {
    background: linear-gradient($pumpkinOrange, $brownishOrange);
    opacity: 1;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .swiperSlide {
    background: #242424;
    text-align: center;

    img {
      height: 224px;
    }
  }
}
</style>
